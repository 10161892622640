/*
 * @Date: 2023-03-22 10:20:51
 * @LastEditors: simLarry
 * @LastEditTime: 2023-03-22 11:00:36
 * @FilePath: \J1-M1-QRP-CODE\pages\_app.js
 */
import App from 'next/app';
import Head from 'next/head';

import React from 'react';

import { ConfigProvider } from 'antd';

import '$DATA/Less/merge.less';



export default class MyApp extends App {
	render(){
		const { Component, pageProps } = this.props;
		let pathname = global.location && global.location.pathname.split('/')[1] || 'm1-001';

		return (
			<>
				<Head>
					<link rel="icon" type="image/png" sizes="32x32" href="/static/images/favicon.ico"></link>
					<title>{pathname && pathname === 'restrict' ? '404 JBO竞博官网|全球领先体育投注，高端手机APP流畅体验' : pathname === 'm1-005' ? 'JBO2022 真人游戏' : pathname === 'm1-004' ? 'JBO 2022 棋牌彩票' : '竞博JBO - 以游戏特点为中心的竞猜平台'} </title>
				</Head>
				<ConfigProvider>
					<Component { ...pageProps } />
				</ConfigProvider>
			</>
		)
	}
}